import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export type Alert = {
  id: string | number
  message: string
}

export const alertAdapter = createEntityAdapter<Alert>()

export const alertSelectors = alertAdapter.getSelectors((state: RootState) => state.alerts)

export const initialState = alertAdapter.getInitialState()

export const { actions, reducer } = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    addAlert: (state, action: PayloadAction<Alert>) => {
      alertAdapter.upsertOne(state, action.payload)
    },
    removeAlert: (state, action: PayloadAction<Alert['id']>) => {
      alertAdapter.removeOne(state, action.payload)
    },
  },
})

export const { addAlert, removeAlert } = actions