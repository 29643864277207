import React, { useEffect } from 'react'
import type { AppProps } from 'next/app'
import { App } from '../components/app/app'
import '../../public/fonts/fonts.css'

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side')

    if (jssStyles) {
      jssStyles.remove()

      if (typeof performance !== 'undefined') {
        performance.mark('Remove JSS')
      }
    }
  }, [])

  return (
    <App>
      <Component { ...pageProps } />
    </App>
  )
}

export default MyApp