import { rem } from './utils/rem'
import deepmerge from 'deepmerge'
import createTheme from '@material-ui/core/styles/createTheme'

export const theme = {
  ...createTheme({
    spacing: factor => rem(factor * 10),
    typography: {
      fontFamily: 'Apex Rounded, sans-serif',
      fontSize: 17,
      htmlFontSize: 17,
      body1: {
        fontSize: '1.25rem',
        color: '#4d4d4d',
        fontWeight: 'normal',
      },
      body2: {
        fontSize: '1rem',
        color: '#4d4d4d',
        fontWeight: 400,
        lineHeight: '2.05rem',
      },
      h1: {
        fontSize: '2.3rem',
      },
      h2: {
        fontSize: '2.3rem',
      },
      h3: {
        fontSize: '1.8rem',
      },
      h4: {
        fontSize: '1.6rem',
      },
      h5: {
        fontSize: '1.12rem',
        textTransform: 'uppercase',
      },
    },
    palette: {
      text: {
        primary: '#1c1f2a',
        secondary: '#4d4d4d',
        disabled: 'rgba(0, 0, 0, 0.2)',
      },
      primary: {
        main: '#f35a21',
        dark: '#1c1f2a',
        light: '#517F34',
      },
      secondary: {
        main: '#ed8b00',
        light: '#ffb81c',
        dark: '#d83b00',
      },
      common: {
        white: '#fff',
      },
      background: {
        default: '#fff',
      },
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
  }),
  custom: {
    spacing: {
      paragraph: '2.5em',
    },
  },
}

theme.custom = deepmerge(theme.custom, {
  spacing: {
    paragraph: rem(34, theme),
  },
})

theme.typography.pxToRem = (px: number) => rem(px, theme)

theme.overrides = {
  MuiContainer: {
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),

      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    maxWidthXs: {
      [theme.breakpoints.up('xs')]: {
        maxWidth: rem(444 + (20 * 2)),
      },
    },
    maxWidthSm: {
      [theme.breakpoints.up('sm')]: {
        maxWidth: rem(600 + (20 * 2)),
      },
    },
    maxWidthMd: {
      [theme.breakpoints.up('md')]: {
        maxWidth: rem(960 + (20 * 2)),
      },
    },
    maxWidthLg: {
      [theme.breakpoints.up('lg')]: {
        maxWidth: rem(1348 + (20 * 2)),
      },
    },
  },
  MuiTypography: {
    h1: {
      [theme.breakpoints.up('xs')]: {
        fontSize: theme.typography.pxToRem(30),
      },
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(38),
      },
    },
    h2: {
      color: '#1C1F2A',
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: theme.typography.pxToRem(34),
      marginTop: theme.typography.pxToRem(38 * 1.2),
      marginBottom: theme.typography.pxToRem(38),
      [theme.breakpoints.up('xs')]: {
        fontSize: theme.typography.pxToRem(23),
      },
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(32),
        lineHeight: theme.typography.pxToRem(45),
      },
    },
    h3: {
      fontWeight: theme.typography.fontWeightMedium,
      color: '#1C1F2A',
      marginTop: '1.5rem',
      marginBottom: '1em',
      lineHeight: 1.6,
      [theme.breakpoints.up('xs')]: {
        fontSize: theme.typography.pxToRem(20),
      },
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(24),
      },
    },
    h4: {
      fontWeight: theme.typography.fontWeightMedium,
      color: '#1C1F2A',
      marginTop: '1.5em',
      marginBottom: '1.3em',
      [theme.breakpoints.up('xs')]: {
        fontSize: theme.typography.pxToRem(18),
      },
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(22),
        lineHeight: theme.typography.pxToRem(35),
      },
    },
    h5: {
      color: '#1C1F2A',
      letterSpacing: 1.7,
      marginTop: theme.typography.pxToRem(15),
      marginBottom: theme.typography.pxToRem(10),
      [theme.breakpoints.up('xs')]: {
        fontSize: theme.typography.pxToRem(15),
      },
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(17),
      },
    },
    h6: {
      color: '#4d4d4d',
      marginBottom: '1.8em',
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(30),
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(22),
        lineHeight: theme.typography.pxToRem(35),
      },
    },
    body1:{
      [theme.breakpoints.up('xs')]: {
        fontSize: theme.typography.pxToRem(17),
      },
    },
    body2:{
      fontSize: theme.typography.pxToRem(15),
      lineHeight: theme.typography.pxToRem(28),
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(17),
        lineHeight: theme.typography.pxToRem(35),
      },
    },
  },
  MuiButton: {
    root: {
      textTransform: 'none',
      lineHeight: 1,
      margin: undefined,
    },
    outlined: {
      paddingTop: theme.typography.pxToRem(12),
      paddingRight:theme.typography.pxToRem(10),
      paddingBottom: theme.typography.pxToRem(11),
      paddingLeft: theme.typography.pxToRem(14),
      color: theme.palette.primary.dark,
      borderColor: theme.palette.primary.dark,
      borderWidth: '2px',
    },
    outlinedPrimary: {
      paddingTop: theme.typography.pxToRem(12),
      paddingRight:theme.typography.pxToRem(10),
      paddingBottom: theme.typography.pxToRem(11),
      paddingLeft: theme.typography.pxToRem(14),
      color: theme.palette.secondary.dark,
      borderColor: theme.palette.secondary.dark,
      borderWidth:'2px',
      '&:hover': {
        backgroundColor: 'transparent',
        border: '2px solid',
        borderColor: theme.palette.secondary.dark,
      },
    },
    outlinedSecondary: {
      paddingTop: theme.typography.pxToRem(12),
      paddingRight:theme.typography.pxToRem(10),
      paddingBottom: theme.typography.pxToRem(11),
      paddingLeft: theme.typography.pxToRem(14),
      color: theme.palette.primary.light,
      borderColor: theme.palette.primary.light,
      borderWidth:'2px',
      '&:hover': {
        backgroundColor: 'transparent',
        border: '2px solid',
        borderColor: theme.palette.primary.light,
      },
    },
    outlinedSizeSmall : {
      fontSize: theme.typography.pxToRem(12),
      padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(9)}`,
      borderWidth:'2px',
    },
    containedPrimary: {
      fontSize: theme.typography.pxToRem(16),
      padding:`${theme.typography.pxToRem(15)} ${theme.typography.pxToRem(25)}`,
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderRadius: '8px',
      boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.1)',
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(25),
        padding: `${theme.typography.pxToRem(22)} ${theme.typography.pxToRem(35)}`,
      },
    },
    containedSecondary : {
      padding: '14px 20px',
      backgroundColor: 'transparent',
      border: `solid 2px ${theme.palette.secondary.dark}`,
      color: theme.palette.common.white,
      borderWidth: '2px',
      [theme.breakpoints.up('md')]: {
        padding: '12px 25px',
      },
    },
    containedSizeSmall: {
      padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(9)}`,
    },
    textPrimary: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
      textDecoration: 'underline',
      border: 'none',
      '&:hover': {
        color: theme.palette.primary.dark,
        backgroundColor: 'transparent',
        textDecoration: 'underline',
      },
    },
    endIcon: {
      marginLeft: '2px',
    },
  },
}

export type ThemeType = typeof theme